import React from "react";

function SearchCom() {
  return (
    <div className="flex-1 relative">
      <input
        type="text"
        placeholder="Search for something"
        className="w-full py-2 px-4 pl-10 rounded-full bg-white border border-gray-200"
      />
      <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400">
        <img src="/assets/search.svg" className="w-5" alt="" />
      </span>
    </div>
  );
}

export default SearchCom;
