import React from 'react'
import Sidebar from '../../components/SideBar'
import MemberDashboard from './components/MemberDashboard'

function Membershipmanagement() {
  return (
    <div className='flex bg-white'>
        <div className='bg-[#272938]'><Sidebar/></div>
        <div className='w-full'><MemberDashboard/></div>
    </div>
  )
}

export default Membershipmanagement
