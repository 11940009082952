import React, { useEffect, useState } from 'react';
import { Search, Filter, Plus } from 'lucide-react';
import SponsorCard from './components/SponsorCard';
import SponsorModal from './components/SponsorActions';
import ConfirmationModal from '../../components/ConfirmationModal';
import Sidebar from '../../components/SideBar';
import SearchCom from '../../components/SearchCom';

const SponsorshipManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [confirmationType, setConfirmationType] = useState('');
  const [editingSponsor, setEditingSponsor] = useState(null);
  const [sponsors, setSponsors] = useState([]);

  const fetchData = async () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/sponsorship/get`)
      .then((response) => response.json())
      .then((data) => setSponsors(data));
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleAddNew = () => {
    setEditingSponsor(null);
    setIsModalOpen(true);
  };

  const handleManage = (sponsor) => {
    setEditingSponsor(sponsor);
    setIsModalOpen(true);
  };

  const handleDelete = (sponsor) => {
    setEditingSponsor(sponsor);
    setConfirmationType('Delete');
    setIsConfirmationOpen(true);
  };

  const handleConfirmDelete = () => {
    setSponsors(sponsors.filter(s => s !== editingSponsor));
    fetch(`${process.env.REACT_APP_BASE_URL}/sponsorship/delete/${editingSponsor._id}`, {
      method: 'DELETE',
    });
    setIsConfirmationOpen(false);
  };

  const handleSaveSponsor = async (sponsorData) => {
    console.log(sponsorData);
  
    try {
      if (editingSponsor) {
        const formData = new FormData();

        formData.append('name', sponsorData.name);
        formData.append('link', sponsorData.link || '');
        formData.append('multipleRepetition', sponsorData.multipleRepetition);
        formData.append('description', sponsorData.description);
        formData.append('rating', sponsorData.rating);
  
        if (sponsorData.image instanceof File) {
          console.log('Uploading new image:', sponsorData.image);
          formData.append('image', sponsorData.image);
        } else {
          console.log('Using existing image URL');
        }
  
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/sponsorship/edit/${editingSponsor._id}`,
          {
            method: 'PUT',
            body: formData,
          }
        );
  
        if (!response.ok) {
          throw new Error('Failed to update sponsor');
        }
  
        const updatedSponsor = await response.json();
        console.log('After Updating', updatedSponsor);
        fetchData();
      } else {
        const formData = new FormData();
  
        formData.append('name', sponsorData.name);
        formData.append('link', sponsorData.link || '');
        formData.append('multipleRepetition', sponsorData.multipleRepetition);
        formData.append('description', sponsorData.description);
        formData.append('rating', sponsorData.rating);
  
        if (sponsorData.image instanceof File) {
          console.log('Uploading new image:', sponsorData.image);
          formData.append('image', sponsorData.image);
        } else {
          console.log('Using existing image URL');
        }
  
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/sponsorship/add`, {
          method: 'POST',
          body: formData,
        });
  
        if (!response.ok) {
          throw new Error('Failed to add sponsorship');
        }
  
        const newSponsor = await response.json();
        console.log('After Adding', newSponsor);
        fetchData();
        setSponsors([...sponsors, newSponsor]);
      }
  
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error saving sponsor:', error.message);
      alert('Failed to save sponsor. Please try again.');
    }
  };

  return (
    <div className='flex bg-white'>
      <div className='bg-[#272938]'><Sidebar /></div>
      <div className='w-full'>
        <div className="p-6 bg-gray-50 min-h-screen max-h-screen overflow-scroll">
          <div className="max-w-6xl mx-auto mb-8 flex items-center justify-between gap-4">
            <div className="flex-1 relative"><SearchCom /></div>
            <button className="px-4 py-2 border rounded-md hover:bg-gray-50 transition-colors flex items-center gap-2"><Filter size={20} /> FILTER</button>
            <button onClick={handleAddNew} className="px-4 pr-6 py-2 bg-gray-900 text-white rounded-md hover:bg-gray-800 transition-colors flex items-center gap-2"><Plus size={20} /> ADD</button>
          </div>

          <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {sponsors.map((sponsor, index) => (
              <SponsorCard key={index} sponsor={sponsor} onDelete={handleDelete} onManage={handleManage} />
            ))}
          </div>

          <SponsorModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            sponsor={editingSponsor}
            isEditing={!!editingSponsor}
            onSave={handleSaveSponsor}
          />

          <ConfirmationModal
            isOpen={isConfirmationOpen}
            onClose={() => setIsConfirmationOpen(false)}
            onConfirm={handleConfirmDelete}
            type={confirmationType}
          />
        </div>
      </div>
    </div>
  );
};

export default SponsorshipManagement;
