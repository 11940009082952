import React from 'react'

// Sponsor Card Component
const SponsorCard = ({ sponsor, onDelete, onManage }) => {

    return (
      <div className="bg-white p-4 rounded-xl shadow-lg">
        <div className="h-32 mb-4 flex items-center justify-center bg-gray-50 rounded-lg">
          {
            sponsor.image instanceof File ? (
              <img src={URL.createObjectURL(sponsor.image)} alt="Preview1" className="max-h-full max-w-full object-contain rounded-lg" />
            ) : (
              <img src={`${process.env.REACT_APP_BASE_URL}/uploads/${sponsor.image}`} alt="Preview2" className="max-h-full max-w-full object-contain rounded-lg" />
            )
          }
        </div>
        <h3 className="text-center font-medium mb-4">{sponsor.name}</h3>
        <div className="grid grid-cols-2 gap-2">
          <button
            onClick={() => onDelete(sponsor)}
            className="px-4 py-2 text-gray-600 border rounded-md hover:bg-gray-50 transition-colors"
          >
            Delete
          </button>
          <button
            onClick={() => onManage(sponsor)}
            className="px-4 py-2 text-white bg-gray-900 rounded-md hover:bg-gray-800 transition-colors"
          >
            Manage
          </button>
        </div>
      </div>
    );
};

export default SponsorCard
