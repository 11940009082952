import React from 'react';
import { X } from 'lucide-react';

const MemberModal = ({ memberDetails, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-y-auto">
      <div className="text-center sm:block sm:p-0 lg:w-[60%]">
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="flex justify-between items-start">
              <h3 className="text-lg font-medium text-gray-900">Member Details</h3>
              <button
                onClick={onClose}
                className="text-gray-400 hover:text-gray-500"
              >
                <img src="/assets/cancel.svg" className='w-6' alt="" />
              </button>
            </div>
            <div className="mt-4 space-y-4">
                <div>
                    <h4 className="font-semibold text-gray-700">General Information</h4>
                    <p className="text-sm text-gray-500"><strong>Request ID:</strong> {memberDetails.requestId}</p>
                    <p className="text-sm text-gray-500"><strong>User ID:</strong> {memberDetails.userId}</p>
                    <p className="text-sm text-gray-500"><strong>Name:</strong> {memberDetails.name}</p>
                    <p className="text-sm text-gray-500"><strong>Email:</strong> {memberDetails.email}</p>
                    <p className="text-sm text-gray-500"><strong>Phone:</strong> {memberDetails.phone}</p>
                    <p className="text-sm text-gray-500"><strong>Address:</strong> {memberDetails.address}</p>
                </div>

                <div>
                    <h4 className="font-semibold text-gray-700">Plan Details</h4>
                    <p className="text-sm text-gray-500"><strong>Plan Type:</strong> {memberDetails.plan.type}</p>
                    <p className="text-sm text-gray-500"><strong>Validity:</strong> {memberDetails.plan.validity}</p>
                    <p className="text-sm text-gray-500"><strong>Members Under Plan:</strong> {memberDetails.plan.membersUnderPlan}</p>
                    <p className="text-sm text-gray-500"><strong>Managed By:</strong> {memberDetails.plan.managedBy}</p>
                </div>

                <div>
                    <h4 className="font-semibold text-gray-700">Personal Details</h4>
                    <p className="text-sm text-gray-500"><strong>From:</strong> {memberDetails.personalDetails.from}</p>
                    <p className="text-sm text-gray-500"><strong>Reason for Moving:</strong> {memberDetails.personalDetails.reasonForMoving}</p>
                    <p className="text-sm text-gray-500"><strong>Father's Name:</strong> {memberDetails.personalDetails.fatherName}</p>
                    <p className="text-sm text-gray-500"><strong>Mother's Name:</strong> {memberDetails.personalDetails.motherName}</p>
                    <p className="text-sm text-gray-500"><strong>Spouse's Name:</strong> {memberDetails.personalDetails.spouseName}</p>
                </div>

                <div>
                    <h4 className="font-semibold text-gray-700">Work Details</h4>
                    <p className="text-sm text-gray-500"><strong>Occupation Type:</strong> {memberDetails.workDetails.occupationType}</p>
                </div>
            </div>

          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              onClick={onClose}
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberModal;
