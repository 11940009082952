import React, { useEffect, useState } from 'react';
import { X, Calendar, Clock, MapPin, Upload } from 'lucide-react';

const AddEventModal = ({ isOpen, onClose, eventData, onSave }) => {
    const [images, setImages] = useState([null, null, null]);
    const [formData, setFormData] = useState({
        name: eventData?.name || '',
        description: eventData?.description || '',
        date: eventData?.date || '',
        time: '',
        location: eventData?.location || '',
        manageThrough: '-Select-',
    });

    useEffect(() => {
        if (eventData) {
            setFormData({
                name: eventData.name,
                description: eventData.description,
                date: eventData.date,
                location: eventData.location,
                manageThrough: '-Select-',
            });
            setImages(eventData.images);
        }
    }, [eventData]);

    const handleImageUpload = (index) => (event) => {
        const file = event.target.files[0];
        if (file) {
            const newImages = [...images];
            newImages[index] = file;
            setImages(newImages);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(formData, images, eventData?._id);
        setFormData({ name: "", description: "", date: "", location: "" }); 
        setImages([null, null, null]); 
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-xl px-14 w-[60%] relative h-[90%] overflow-scroll py-4">
                {/* Close Button */}
                <button onClick={()=>{setFormData({ name: "", description: "", date: "", location: "" });setImages([null,null,null]);onClose()}} className="absolute right-4 top-4 text-gray-500 hover:text-gray-700">
                    <img src="/assets/cancel.svg" alt="" />
                </button>

                <form onSubmit={handleSubmit} className="space-y-6">
                    {/* Image Upload Section */}
                    <div className="space-y-2">
                        <label className="block text-sm font-medium">Add Image</label>
                        <div className="flex gap-4">
                            {[0, 1, 2].map((index) => (
                                <div
                                    key={index}
                                    className="w-32 h-32 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center cursor-pointer hover:border-gray-400 transition-colors"
                                >
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageUpload(index)}
                                        className="hidden"
                                        id={`image-${index}`}
                                    />
                                    <label htmlFor={`image-${index}`} className="w-full h-full flex items-center justify-center" >
                                        {images?.[index] ? (
                                            images[index] instanceof File ? (
                                            <img
                                                src={URL.createObjectURL(images[index])}
                                                alt={`Image ${index + 1}`}
                                                className="w-full h-full object-cover rounded-lg"
                                            />
                                            ) : (
                                            <img
                                                src={`${images[index]}`}
                                                alt={`Image ${index + 1}`}
                                                className="w-full h-full object-cover rounded-lg"
                                            />
                                            )
                                        ) : (
                                            <div className="text-center">
                                            <Upload className="mx-auto text-gray-400 mb-2" size={24} />
                                            <span className="text-sm text-gray-500">Image {index + 1}</span>
                                            </div>
                                        )}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Form Fields */}
                    <div className="space-y-4">
                        <div className=''>
                            <label className="block text-sm font-medium mb-1">Name</label>
                            <input
                                type="text"
                                placeholder="Write a Event Name"
                                className="w-full px-3 py-2 border rounded-lg"
                                value={formData.name}
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1">Description</label>
                            <textarea
                                placeholder="Write a description about the Event (Max: 100 words)"
                                className="w-full px-3 py-2 border rounded-lg"
                                rows="2"
                                value={formData.description}
                                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                            />
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium mb-1">Date</label>
                                <div className="relative">
                                    <input
                                        type="date"
                                        className="w-full px-3 py-2 border rounded-lg pl-10"
                                        value={formData.date}
                                        onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                                    />
                                    <Calendar className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={16} />
                                </div>
                            </div>

                            <div>
                                <label className="block text-sm font-medium mb-1">Time</label>
                                <input
                                    type="time"
                                    className="w-full px-3 py-2 border rounded-lg"
                                    value={formData.time}
                                    onChange={(e) => setFormData({ ...formData, time: e.target.value })}
                                />
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1">Location</label>
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Enter a location"
                                    className="w-full px-3 py-2 border rounded-lg pl-10"
                                    value={formData.location}
                                    onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                                />
                                <MapPin className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" size={16} />
                            </div>
                        </div>

                        <div className="flex justify-center">
                            <button type="submit" className="bg-gray-900 text-white px-6 py-3 rounded-lg">
                                Save Event
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddEventModal;
