import React, { useEffect, useState } from 'react';
import { Upload } from 'lucide-react';

const MemberModal = ({ isOpen, onClose, member, isEditing, onSave }) => {
    const [formData, setFormData] = useState({
        image: member?.image || null,
        name: member?.name || '',
        designation: member?.designation || '',
        description: member?.description || ''
    });

    useEffect(() => {
        if (member) {
            setFormData({
                image: member.image || null,
                name: member.name || '',
                designation: member.designation || '',
                description: member.description || ''
            });
        } else {
            setFormData({
                image: null,
                name: '',
                designation: '',
                description: ''
            });
        }
    }, [member]);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFormData({ ...formData, image: file });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave({ ...formData });
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-xl p-6 w-full max-w-md relative">
                <button onClick={onClose} className="absolute right-4 top-4 text-gray-500 hover:text-gray-700">
                    <img src="/assets/cancel.svg" alt="" />
                </button>
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="flex justify-center">
                        <div className="relative">
                            <input type="file" accept="image/*" onChange={handleImageUpload} className="hidden" id="member-image" />
                            <label htmlFor="member-image" className="block w-24 h-24 rounded-full border-2 border-dashed border-gray-300 cursor-pointer hover:border-gray-400 transition-colors">
                                {formData?.image ? (
                                    formData.image instanceof File ? (
                                        <img src={URL.createObjectURL(formData.image)} alt="Preview1" className="max-h-full max-w-full object-contain rounded-lg" />
                                    ) : (
                                        <img src={`${process.env.REACT_APP_BASE_URL}/uploads/${formData.image}`} alt="Preview2" className="max-h-full max-w-full object-contain rounded-lg" />
                                    )
                                ) :(
                                    <div className="w-full h-full flex items-center justify-center">
                                        <Upload className="text-gray-400" size={24} />
                                    </div>
                                )}
                            </label>
                        </div>
                    </div>
                    <input type="text" placeholder="Name" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} className="w-full px-3 py-2 border rounded-lg" />
                    <input type="text" placeholder="Designation" value={formData.designation} onChange={(e) => setFormData({ ...formData, designation: e.target.value })} className="w-full px-3 py-2 border rounded-lg" />
                    <textarea placeholder="Description" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} className="w-full px-3 py-2 border rounded-lg" rows="4"></textarea>
                    <button type="submit" className="w-full bg-gray-900 text-white py-3 rounded-full hover:bg-gray-800 transition-colors">
                        {isEditing ? 'Save Changes' : 'Add to Team'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default MemberModal;
