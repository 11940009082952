import React, { useState } from 'react';
import TicketCard from './TicketCard';
import EditTicketModal from './EditTicketModal';
import SearchCom from '../../../components/SearchCom';

const SupportDeskManagerCon = () => {
  const [tickets, setTickets] = useState([
    {
      id: '2501',
      name: 'Mr. Rajesh Kumar',
      email: 'example@example.com',
      phone: '9876543210',
      address: 'No.04 ABC Street Adelaide',
      issue: 'Issue description here',
      status: 'live'
    },
    {
      id: '2502',
      name: 'Mr. Rajesh Kumar',
      email: 'example@example.com',
      phone: '9876543210',
      address: 'No.04 ABC Street Adelaide',
      issue: 'Issue description here',
      status: 'completed'
    },
    {
      id: '2503',
      name: 'Mr. Rajesh Kumar',
      email: 'example@example.com',
      phone: '9876543210',
      address: 'No.04 ABC Street Adelaide',
      issue: 'Issue description here',
      status: 'completed'
    },
    {
      id: '2504',
      name: 'Mr. Rajesh Kumar',
      email: 'example@example.com',
      phone: '9876543210',
      address: 'No.04 ABC Street Adelaide',
      issue: 'Issue description here',
      status: 'live'
    }
  ]);
  
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [activeTab, setActiveTab] = useState('live');

  const handleEdit = (ticket) => {
    setSelectedTicket({ ...ticket });
  };

  const handleSave = () => {
    setTickets(tickets.map(t => t.id === selectedTicket.id ? selectedTicket : t));
    setSelectedTicket(null);
  };

  const handleClose = () => setSelectedTicket(null);

  const handleChange = (field, value) => {
    setSelectedTicket(prev => ({ ...prev, [field]: value }));
  };

  const handleMarkAsCompleted = (ticketId) => {
    setTickets(tickets.map(t => 
      t.id === ticketId ? { ...t, status: 'completed' } : t
    ));
    handleClose();
  };

  return (
    <div className="bg-gray-100 min-h-screen max-h-screen w-full px-10 overflow-scroll">
            <div className="py-5 mx-auto flex items-center justify-between gap-4">
                <div className='w-[70%] flex gap-6'>
                    <SearchCom />
                </div>
                <div><img src="/assets/mask.svg" alt="" /></div>
            </div>
            <div className="p-4 max-w-7xl mx-auto">
                <div className="w-[90%] mx-auto flex gap-4 mb-8">
                    <button
                    className={`flex-1 px-4 py-2 ${activeTab === 'live' ? 'border-b-2 border-[rgb(40,45,70)] text-[rgb(40,45,70)]' : 'text-gray-500'}`}
                    onClick={() => setActiveTab('live')}
                    >
                    Live
                    </button>
                    <button
                    className={`flex-1 px-4 py-2 ${activeTab === 'completed' ? 'border-b-2 border-[rgb(40,45,70)] text-[rgb(40,45,70)]' : 'text-gray-500'}`}
                    onClick={() => setActiveTab('completed')}
                    >
                    Completed
                    </button>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {tickets
                    .filter(t => t.status === activeTab)
                    .map((ticket) => (
                        <TicketCard 
                        key={ticket.id} 
                        ticket={ticket} 
                        onEdit={handleEdit} 
                        onMarkAsCompleted={handleMarkAsCompleted} 
                        />
                    ))}
                </div>

                <EditTicketModal 
                    ticket={selectedTicket} 
                    onClose={handleClose} 
                    onSave={handleSave} 
                    onChange={handleChange}
                    onMarkAsCompleted={handleMarkAsCompleted}
                />
            </div>
    </div>
  );
};

export default SupportDeskManagerCon;
