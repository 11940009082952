import React, { useEffect, useRef, useState } from 'react';
import { Filter, PenBoxIcon, Upload, ChevronLeftIcon, ImageUpIcon, Trash } from 'lucide-react';
import SearchCom from '../../../components/SearchCom';

const AutoResizeTextarea = ({ value, onChange, placeholder }) => {
    const textareaRef = useRef(null);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [value]);

    return (
        <textarea
            ref={textareaRef}
            placeholder={placeholder}
            className="w-full p-2 border-l-2 pl-5 border-gray-400 resize-none outline-none"
            value={value}
            onChange={onChange}
            style={{ minHeight: '32px' }}
        />
    );
};

const BlogPostForm = ({ selectedBlog, isExpanded, onCollapse, onSave }) => {
    const [formData, setFormData] = useState({
        title: '',
        content: '',
        image: null,
        imageUrl: null,
    });
    const [error, setError] = useState('');

    useEffect(() => {
        if (selectedBlog) {
            console.log(selectedBlog.imageUrl)
            setFormData({
                title: selectedBlog.name,
                content: selectedBlog.desc,
                image: selectedBlog.image,
                imageUrl: selectedBlog.imageUrl,
            });
        } else {
            setFormData({ title: '', content: '', image: null, imageUrl: null });
        }
    }, [selectedBlog]);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setFormData({ ...formData, image: file, imageUrl });
        }
    };

    const validateForm = () => {
        if (!formData.title) {
            setError('Title is required');
            return false;
        }
        if (!formData.content) {
            setError('Content is required');
            return false;
        }
        if (!formData.image) {
            setError('Image is required');
            return false;
        }
        setError('');
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            onSave(formData);
            onCollapse();
            setFormData({ title: '', content: '', image: null, imageUrl: null });
        }
    };

    if (!isExpanded) return null;

    return (
        <div className="bg-white rounded-lg p-6 mb-6">
            <div className='w-full flex justify-between mb-8'>
                <div>
                    <button onClick={onCollapse} className="w-fit py-4 text-gray-500 rounded-md hover:text-gray-900 transition-colors flex items-center gap-2">
                        <ChevronLeftIcon size={20} />
                        Back
                    </button>
                </div>
                <div>
                    <button 
                        onClick={handleSubmit}
                        className="w-fit py-4 text-gray-500 rounded-md hover:text-gray-900 transition-colors flex items-center gap-2" 
                    >
                        <Upload size={20} /> {selectedBlog ? 'Update' : 'Publish'}
                    </button>
                </div>
            </div>
            <div className="space-y-4">
                <input
                    type="text"
                    placeholder="Title"
                    className="w-full p-2 text-3xl border-l-2 pl-5 border-gray-400 outline-none"
                    value={formData.title}
                    onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                />

                <AutoResizeTextarea
                    placeholder="Write a Blog Content"
                    value={formData.content}
                    onChange={(e) => setFormData({ ...formData, content: e.target.value })}
                />
                
                <div className="border-l-2 border-gray-400 p-8 text-center">
                    {
                        formData.imageUrl === null ? 
                        <>
                            <input type="file" id="image-upload" className="hidden" onChange={handleImageChange} accept="image/*" />
                            <label htmlFor="image-upload" className="cursor-pointer">
                                <ImageUpIcon className="mx-auto mb-2 text-neutral-600" size={24} />
                                <span className="text-gray-600">Click to upload an image</span>
                            </label>
                        </>
                        :
                        
                        formData?.image instanceof File ? (
                            <img src={URL.createObjectURL(formData.image)} alt="Preview1" className="max-h-full max-w-full object-contain rounded-lg" />
                        ) : (
                            <img src={`${process.env.REACT_APP_BASE_URL}/uploads/${formData.image}`} alt="Preview2" className="max-h-full max-w-full object-contain rounded-lg" />
                        )
                    }
                </div>
                {error && <p className="text-red-600 mt-4">{error}</p>}
                {
                    formData.image &&
                    <div
                        className='flex gap-3 text-red-600 cursor-pointer mt-4'
                        onClick={
                            () => setFormData({ ...formData, imageUrl: null })
                        }>
                            <Trash size={20} />
                            Remove this Image
                    </div>
                }
            </div>
        </div>
    );
};

const BlogPostCard = ({ post, onManage, onDelete }) => {
    
    console.log(post)
    return (
    <div className="bg-white rounded-lg shadow overflow-hidden">

        {
            post?.image instanceof File ? (
                <img src={URL.createObjectURL(post.image)} alt="Preview1" className="max-h-full max-w-full object-contain rounded-lg" />
              ) : (
                <img src={`${process.env.REACT_APP_BASE_URL}/uploads/${post.image}`} alt="Preview2" className="max-h-full max-w-full object-contain rounded-lg" />
              )
        }
        <div className="p-4">
            <h3 className="font-semibold text-gray-900 mb-2">{post.name}</h3>
            <p className="text-gray-600 text-sm mb-4">{post.desc}</p>
            <div className="flex flex-col justify-between gap-3 text-sm">
            <span className="text-gray-500">{new Date(post.date).toLocaleDateString('en-US', {year: 'numeric',month: 'long',day: 'numeric',})}</span>
                <div className="flex w-full items-center justify-between">
                    <button onClick={() => onManage(post)} className="px-4 py-1 border border-gray-300 rounded hover:bg-gray-50">
                        Manage
                    </button>
                    <button onClick={() => onDelete(post)} className="px-4 py-1 border border-gray-300 rounded hover:bg-gray-50">
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
    )
}

const BlogManagementCon = () => {
    const [isFormExpanded, setIsFormExpanded] = useState(false);
    const [selectedBlog, setSelectedBlog] = useState(null);
    const [posts, setPosts] = useState([]);

    const fetchData = async () => {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/blog/get`);
        const data = await response.json();
        setPosts(data);
    };

    useEffect(() => {
        fetchData();
    }, [])

    const handleSave = async(data) => { 
        if (selectedBlog) { 
            console.log(selectedBlog)
            console.log('Updating:', data);
            const datas = new FormData(); 
            datas.append('name', data.title); 
            console.log('Appended name:', data.title);
    
            datas.append('desc', data.content); 
            console.log('Appended desc:', data.content);
             
            if (data.image instanceof File) { 
                console.log('Uploading new image:', data.image); 
                datas.append('image', data.image); 
            } else { 
                console.log('No image file to upload'); 
            }
        
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/blog/edit/${selectedBlog._id}`, { 
                method: 'PUT', 
                body: datas, 
            }); 
        
            if (!response.ok) { 
                const errorText = await response.text();
                console.error('Error response:', errorText);
                throw new Error('Failed to add blog'); 
            } 
       
            const newSponsor = await response.json(); 
            console.log('After Adding', newSponsor); 
            fetchData();

        } else { 
      
            const datas = new FormData(); 
            datas.append('name', data.title); 
            console.log('Appended name:', data.title);
    
            datas.append('desc', data.content); 
            console.log('Appended desc:', data.content);
             
            if (data.image instanceof File) { 
                console.log('Uploading new image:', data.image); 
                datas.append('image', data.image); 
            } else { 
                console.log('No image file to upload'); 
            }
        
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/blog/add`, { 
                method: 'POST', 
                body: datas, 
            }); 
       
            if (!response.ok) { 
                const errorText = await response.text();
                console.error('Error response:', errorText);
                throw new Error('Failed to add blog'); 
            } 
       
            const newSponsor = await response.json(); 
            console.log('After Adding', newSponsor); 
            fetchData();
        } 
        setSelectedBlog(null); 
    };

    const handleDelete = (postToDelete) => {
        setPosts(posts.filter((post) => post !== postToDelete));
    };

    const handleManageClick = (post) => {
        setSelectedBlog(post);
        setIsFormExpanded(true);
    };

    return (
        <div className="min-h-screen bg-gray-50 p-6">
            <div className="mx-auto flex items-center justify-between gap-4">
                <div className='w-[70%] flex gap-6'>
                    <SearchCom />
                    <button className="px-4 py-2 border rounded-md hover:bg-gray-50 transition-colors flex items-center gap-2">
                        <Filter size={20} />
                        FILTER
                    </button>
                </div>
                <div><img src="/assets/mask.svg" alt="" /></div>
            </div>

            <div>
                {
                    !isFormExpanded &&
                    <div className='w-full flex justify-end py-3'>
                        <button onClick={() => setIsFormExpanded(true)} className="w-fit py-4 text-gray-900 rounded-md hover:text-gray-500 transition-colors flex items-center gap-2">
                            <PenBoxIcon size={20} /> Write
                        </button>
                    </div>
                }
            </div>

            <BlogPostForm
                selectedBlog={selectedBlog}
                isExpanded={isFormExpanded}
                onCollapse={() => { setIsFormExpanded(false); setSelectedBlog(null); }}
                onSave={handleSave}
            />

            {
                !isFormExpanded &&
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {posts.map((post, index) => (
                        <BlogPostCard
                            key={index}
                            post={post}
                            onManage={handleManageClick}
                            onDelete={handleDelete}
                        />
                    ))}
                </div>
            }
        </div>
    );
};

export default BlogManagementCon;
