import React from 'react'
import Sidebar from '../../components/SideBar'
import SupportDeskManagerCon from './components/MainContainer'

function SupportDeskManagerment() {
  return (
    <div className='flex bg-white'>
        <div className='bg-[#272938]'><Sidebar/></div>
        <div className='w-full'><SupportDeskManagerCon/></div>
    </div>
  )
}

export default SupportDeskManagerment
