import React, { useEffect, useState } from 'react';
import SearchCom from '../../components/SearchCom';
import TeamMemberCard from './components/TeamMemberCard';
import MemberModal from './components/MemberActions';
import Sidebar from '../../components/SideBar';

const TeamManagement = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingMember, setEditingMember] = useState(null);
    const [members, setMembers] = useState([
        
    ]);

    const fetchData = async () => {
        fetch(`${process.env.REACT_APP_BASE_URL}/teammanagement/get`)
        .then((response) => response.json())
        .then((data) => {console.log(data);setMembers(data)});
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleEditMember = (member) => {
        setEditingMember(member);
        console.log(member)
        setIsModalOpen(true);
    };

    const handleAddNew = () => {
        setEditingMember(null);
        setIsModalOpen(true);
    };

    // Callback to add or edit a member in the list
    const handleSaveMember = async (memberData) => {
        console.log(memberData)
        if (editingMember) {
            console.log('Editing', memberData);
            const formData = new FormData();
  
            formData.append('name', memberData.name);
            formData.append('designation', memberData.designation);
            formData.append('description', memberData.description);
      
            if (memberData.image instanceof File) {
              console.log('Uploading new image:', memberData.image);
              formData.append('image', memberData.image);
            } else {
                console.log('Using existing image URL');
            }
      
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/teammanagement/edit/${editingMember._id}`, {
              method: 'PUT',
              body: formData,
            });
      
            if (!response.ok) {
              throw new Error('Failed to edit team member');
            }
      
            const newMember = await response.json();
            console.log('After Editing', newMember);
            fetchData();
        } else {
            const formData = new FormData();
  
            formData.append('name', memberData.name);
            formData.append('designation', memberData.designation);
            formData.append('description', memberData.description);
      
            if (memberData.image instanceof File) {
              console.log('Uploading new image:', memberData.image);
              formData.append('image', memberData.image);
            } else {
              console.log('Using existing image URL');
            }
      
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/teammanagement/add`, {
              method: 'POST',
              body: formData,
            });
      
            if (!response.ok) {
              throw new Error('Failed to add sponsorship');
            }
      
            const newMember = await response.json();
            console.log('After Adding', newMember);
            fetchData();
        }
        setIsModalOpen(false);
    };

    return (
        <div className='flex bg-white max-h-screen'>
            <div className='bg-[#272938]'><Sidebar/></div>
            <div className='w-full min-h-screen max-h-screen overflow-scroll'>
                <div className="p-6 bg-gray-50">
                    <div className="max-w-6xl mx-auto mb-8 flex items-center justify-between gap-4">
                        <div className='w-[70%] flex gap-6'>
                            <SearchCom />
                            <button onClick={handleAddNew} className="bg-gray-900 text-white px-4 py-2 rounded-full hover:bg-gray-800 transition-colors flex items-center gap-2">
                                ADD <span>+</span>
                            </button>
                        </div>
                        <div><img src="/assets/mask.svg" alt="" /></div>
                    </div>
                    <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {members.map((member) => (
                            <TeamMemberCard key={member._id} member={member} onEdit={handleEditMember} />
                        ))}
                    </div>
                    <MemberModal 
                        isOpen={isModalOpen} 
                        onClose={() => setIsModalOpen(false)} 
                        member={editingMember} 
                        isEditing={!!editingMember}
                        onSave={handleSaveMember}  // Pass the save callback
                    />
                </div>
            </div>
        </div>
    );
};

export default TeamManagement;
