import React, { useEffect, useState } from 'react';
import SearchCom from '../../../components/SearchCom';
import AddEventModal from './EventActions';
import EventCard from './EventCard';

const EventCardGrid = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null); // Holds event data for editing
    const [eventData, setEventData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);
    
    const fetchData = async () => {
        fetch(`${process.env.REACT_APP_BASE_URL}/events/get`)
        .then((response) => response.json())
        .then((data) => setEventData(data));
    }

    // Function to handle adding or updating an event
    const handleSaveEvent = async (formData, images, eventId) => {
        console.log(eventId);
        if (eventId) {
            console.log(formData, images, eventId);
            const formData_ = new FormData();
  
            formData_.append('name', formData.name);
            formData_.append('location', formData.location);
            formData_.append('time', formData.time);
            formData_.append('date', formData.date);
            formData_.append('description', formData.description);
            formData_.append('manageThrough', formData.manageThrough);
            images.forEach((image) => {
                if (image instanceof File) {
                  formData_.append('images', image);
                }
            });

            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/events/edit/${eventId}`,
                {
                  method: 'PUT',
                  body: formData_,
                }
              );
        
              if (!response.ok) {
                throw new Error('Failed to update sponsor');
              }
        
            const updatedSponsor = await response.json();
            console.log('After Updating', updatedSponsor);
            fetchData();
        } else {
            // Add new event
            console.log(formData, images, eventId);
            const formData_ = new FormData();
  
            formData_.append('name', formData.name);
            formData_.append('location', formData.location);
            formData_.append('time', formData.time);
            formData_.append('date', formData.date);
            formData_.append('description', formData.description);
            formData_.append('manageThrough', formData.manageThrough);
    
            images.forEach((image) => {
                if (image instanceof File) {
                  formData_.append('images', image);
                }
            });
  
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/events/add`, {
                method: 'POST',
                body: formData_,
            });
    
            if (!response.ok) {
            throw new Error('Failed to add sponsorship');
            }
    
            const newSponsor = await response.json();
            console.log('After Adding', newSponsor);
  
            setEventData((prevData) => [
                ...prevData,
                { ...formData, images, id: prevData.length + 1 } 
            ]);
        }
        setIsModalOpen(false);
        setSelectedEvent(null); 
    };

    const handleManageEvent = (event) => {
        setSelectedEvent(event); // Set the selected event data
        setIsModalOpen(true);
    };

    return (
        <div className="bg-gray-100 min-h-screen max-h-screen w-full px-10 overflow-scroll">
            <div className="py-5 mx-auto flex items-center justify-between gap-4">
                <div className='w-[70%] flex gap-6'>
                    <SearchCom />
                    <button onClick={() => { setSelectedEvent(null);setIsModalOpen(true); }} className="bg-gray-900 text-white px-4 py-2 rounded-full hover:bg-gray-800 transition-colors flex items-center gap-2">
                        ADD <span>+</span>
                    </button>
                </div>
                <div><img src="/assets/mask.svg" alt="" /></div>
            </div>

            {/* Grid of cards */}
            <div className="mx-auto mb-6 mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {eventData.map((event) => (
                    <EventCard
                        key={event._id}
                        {...event}
                        onManage={() => handleManageEvent(event)} // Pass the event data for editing
                    />
                ))}
            </div>

            <AddEventModal
                isOpen={isModalOpen}
                onClose={() => { setIsModalOpen(false); setSelectedEvent(null); }}
                eventData={selectedEvent} // Pass selected event data to modal
                onSave={handleSaveEvent} // Pass the save function to modal
            />
        </div>
    );
};

export default EventCardGrid;
