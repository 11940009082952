import React from 'react';

const EditTicketModal = ({ ticket, onClose, onSave, onChange, onMarkAsCompleted }) => {
  if (!ticket) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center">
      <div className="bg-white rounded-lg w-full max-w-lg p-6">
        <div className="space-y-4">
          <div className="space-y-2">
            <label className="text-sm font-medium">User ID</label>
            <input 
              type="text"
              className="w-full p-2 border rounded-md bg-gray-50"
              value={ticket.id}
              disabled
            />
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium">Name</label>
            <input 
              type="text"
              className="w-full p-2 border rounded-md"
              value={ticket.name}
              onChange={(e) => onChange('name', e.target.value)}
              disabled={ticket.status !== 'live'}
            />
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium">Email</label>
            <input 
              type="email"
              className="w-full p-2 border rounded-md"
              value={ticket.email}
              onChange={(e) => onChange('email', e.target.value)}
              disabled={ticket.status !== 'live'}
            />
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium">Phone</label>
            <input 
              type="tel"
              className="w-full p-2 border rounded-md"
              value={ticket.phone}
              onChange={(e) => onChange('phone', e.target.value)}
              disabled={ticket.status !== 'live'}
            />
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium">Address</label>
            <input 
              type="text"
              className="w-full p-2 border rounded-md"
              value={ticket.address}
              onChange={(e) => onChange('address', e.target.value)}
              disabled={ticket.status !== 'live'}
            />
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium">Issue Description</label>
            <textarea 
              className="w-full p-2 border rounded-md"
              value={ticket.issue}
              onChange={(e) => onChange('issue', e.target.value)}
              rows={4}
              disabled={ticket.status !== 'live'}
            />
          </div>
          <div className="flex gap-2 pt-4">
            <button 
              className="flex-1 px-4 py-2 border rounded-md text-red-600 hover:bg-gray-50"
              onClick={onClose}
            >
              Close Ticket
            </button>
            {ticket.status === 'live' && (
              <>
                <button
                    className="flex-1 px-4 py-2 border text-[rgb(40,45,70)] rounded-md"
                >
                    Contact
                </button> 
                <button 
                    className="flex-1 px-4 py-2 bg-[rgb(40,45,70)] text-white rounded-md"
                    onClick={onSave}
                >
                    Save Changes
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTicketModal;
