import BlogManagement from "./admin/pages/BlogManagement/BlogManagement";
import Eventmanagement from "./admin/pages/EventManagement/eventmanagement";
import SponsorshipManagement from "./admin/pages/SponsorManagement/SponsorManagement";
import TeamManagement from "./admin/pages/TeamManagement/TeamManagement";
import UserManagement from "./admin/pages/UserManagement/UserManagement";

import React from 'react';
import { Home, Search, RefreshCcw } from 'lucide-react';

const NotFound = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 flex items-center justify-center p-4">
      <div className="text-center space-y-6 max-w-lg">
        {/* Large 404 Text */}
        <div className="relative">
          <h1 className="text-8xl font-bold text-gray-200">404</h1>
          <div className="absolute inset-0 flex items-center justify-center">
            <span className="text-xl font-medium text-gray-600">Page Not Found</span>
          </div>
        </div>

        {/* Message */}
        <p className="text-gray-600 text-lg">
          Oops! Looks like you've ventured into uncharted territory. 
          The page you're looking for has gone on vacation without leaving a forwarding address.
        </p>

        {/* Helpful Links */}
        <div className="flex flex-col sm:flex-row gap-4 justify-center mt-8">
          <button className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors gap-2">
            <Home size={20} />
            <span>Go Home</span>
          </button>
          
          <button className="inline-flex items-center px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition-colors gap-2">
            <Search size={20} />
            <span>Search Site</span>
          </button>
          
          <button className="inline-flex items-center px-4 py-2 border border-gray-300 bg-white rounded-lg hover:bg-gray-50 transition-colors gap-2">
            <RefreshCcw size={20} />
            <span>Try Again</span>
          </button>
        </div>

        {/* Additional Help */}
        <div className="mt-8 pt-6 border-t border-gray-200">
          <p className="text-gray-500">
            If you think this is a mistake, please contact our support team
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
