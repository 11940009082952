import React, { useState } from 'react';

const MemberTable = ({ members, onViewMore }) => {
  const [filterStatus, setFilterStatus] = useState('Current');

  const getFilteredMembers = () => {
    return members.filter((member) => {
      if (filterStatus === 'Current') {
        return member.status === 'Current';
      } else if (filterStatus === 'Pending for Approval') {
        return member.status === 'Pending';
      } else if (filterStatus === 'Expired') {
        return member.status === 'Expired';
      }
      return true;
    });
  };

  const filteredMembers = getFilteredMembers();

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="px-6 py-4 mb-4">
        <div className="flex gap-8 w-full">
          <button
            onClick={() => setFilterStatus('Current')}
            className={`flex-1 font-medium ${filterStatus === 'Current' ? 'text-[rgb(40,45,70)] border-b-2 border-[rgb(40,45,70)] pb-2' : 'text-gray-500 hover:text-gray-700'}`}
          >
            Current
          </button>
          <button
            onClick={() => setFilterStatus('Pending for Approval')}
            className={`flex-1 ${filterStatus === 'Pending for Approval' ? 'text-[rgb(40,45,70)] border-b-2 border-[rgb(40,45,70)] pb-2' : 'text-gray-500 hover:text-gray-700'}`}
          >
            Pending for Approval
          </button>
          <button
            onClick={() => setFilterStatus('Expired')}
            className={`flex-1 ${filterStatus === 'Expired' ? 'text-[rgb(40,45,70)] border-b-2 border-[rgb(40,45,70)] pb-2' : 'text-gray-500 hover:text-gray-700'}`}
          >
            Expired
          </button>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">User Id</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Member Code</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Name</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Validity</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Plan Type</th>
              <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Action</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {filteredMembers.map((member) => (
              <tr key={member.userId} className="hover:bg-gray-50">
                <td className="px-6 py-4 text-sm text-gray-900">{member.userId}</td>
                <td className="px-6 py-4 text-sm text-gray-900">{member.memberCode}</td>
                <td className="px-6 py-4 text-sm text-gray-900">{member.name}</td>
                <td className="px-6 py-4 text-sm text-gray-900">{member.validity}</td>
                <td className="px-6 py-4 text-sm text-gray-900">{member.planType}</td>
                <td className="px-6 py-4 text-sm">
                  <button
                    onClick={() => onViewMore(member)}
                    className="bg-[rgb(40,45,70)] text-white px-4 py-1 rounded-full"
                  >
                    View More
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MemberTable;
