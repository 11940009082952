import React from 'react'
import BlogManagementCon from './components/BlogsContainer'
import Sidebar from '../../components/SideBar'

const BlogManagement = () => {
  return (
    <div className='flex bg-white'>
        <div className='bg-[#272938]'><Sidebar/></div>
        <div className='w-full max-h-screen overflow-scroll'><BlogManagementCon/></div>
    </div>
  )
}

export default BlogManagement
