import React, { useState } from 'react';
import MemberTable from './MemberTable';
import MemberModal from './MemberModal';
import SearchCom from '../../../components/SearchCom';

const MemberDashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  const members = [
    { userId: '2051', memberCode: 'ATA57', name: 'Rajesh', validity: '14-08-2025', planType: 'Silver-Single', status: 'Pending' },
    { userId: '9277', memberCode: 'ATA27', name: 'Kavin', validity: '12-12-2025', planType: 'Silver-Family', status: 'Approval' },
    { userId: '1234', memberCode: 'ATA58', name: 'Kanmani', validity: 'Life-Time', planType: 'Silver-Family', status: 'Current' },
    { userId: '5678', memberCode: 'ATA24', name: 'Ponmani', validity: 'Life-Time', planType: 'Silver-Family', status: 'Current' },
    { userId: '8526', memberCode: 'ATA52', name: 'Rajeshwari', validity: '14-08-2025', planType: 'Gold-Student', status: 'Approval' },
    { userId: '1258', memberCode: 'ATA20', name: 'Kavya', validity: 'Life-Time', planType: 'Gold-Family', status: 'Pending' },
    { userId: '4598', memberCode: 'ATA34', name: 'Navya', validity: 'Life-Time', planType: 'Gold-Family', status: 'Approval' },
    { userId: '3258', memberCode: 'ATA14', name: 'Murugesan', validity: '18-12-2025', planType: 'Gold-Family', status: 'Current' },
    { userId: '4799', memberCode: 'ATA02', name: 'Senthil', validity: '07-10-2025', planType: 'Silver-Single', status: 'Expired' }
  ];
  

  const memberDetails = {
    requestId: '#88802',
    userId: '2628',
    name: 'Sanjal',
    email: 'example@example.com',
    phone: '9876543210',
    address: 'No.55 south street, KRB Mall, Riverside, Adelaide',
    plan: {
      type: 'Gold-Family',
      validity: 'Life-time',
      membersUnderPlan: 3,
      managedBy: 'Rajesh'
    },
    personalDetails: {
      from: 'Trichy, TN',
      reasonForMoving: 'Education',
      fatherName: 'Kandhasamy',
      motherName: 'Rathna',
      spouseName: 'Unmanied'
    },
    workDetails: {
      occupationType: 'Employee'
    }
  };

  const handleViewMore = (member) => {
    setSelectedMember(member);
    setIsModalOpen(true);
  };

  return (
    <div className="min-h-screen max-h-screen w-full px-10 overflow-scroll">
        <div className="py-5 mx-auto flex items-center justify-between gap-4">
            <div className='w-[70%] flex gap-6'>
                <SearchCom />
                <button className="bg-gray-900 text-white px-4 py-2 rounded-full hover:bg-gray-800 transition-colors flex items-center gap-2">
                    FILTER <span>+</span>
                </button>
                <button className="bg-gray-900 text-white px-4 py-2 rounded-full hover:bg-gray-800 transition-colors flex items-center gap-2">
                    SORT <span>+</span>
                </button>
            </div>
            <div><img src="/assets/mask.svg" alt="" /></div>
        </div>
        <div className="p-6 max-w-6xl mx-auto">
        <MemberTable members={members} onViewMore={handleViewMore} />
        {isModalOpen && (
            <MemberModal memberDetails={memberDetails} onClose={() => setIsModalOpen(false)} />
        )}
        </div>
    </div>
  );
};

export default MemberDashboard;
