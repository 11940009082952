import React, { useState } from 'react';
import FolderCreation from './FolderCreation';
import FolderGrid from './FolderGrid';
import ConfirmationModal from '../../../components/ConfirmationModal';
import SearchCom from '../../../components/SearchCom';

const PhotoManagementCon = () => {
  const [folders, setFolders] = useState([
    {
      id: 1,
      name: 'Diwali 2023',
      date: '2023-05-15',
      images: [
      ],
    },
    {
      id: 2,
      name: 'Pongal Celebration',
      date: '2024-01-15',
      images: [
        { id: 5, url: '/assets/folder12.svg', title: 'Festival Celebration 1' },
        { id: 6, url: '/assets/folder13.svg', title: 'Festival Celebration 2' },
        { id: 7, url: '/assets/folder11.svg', title: 'Festival Celebration 3' },
      ],
    },
  ]);
  
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newFolder, setNewFolder] = useState({ name: '', date: '', images: [] });

  const handleCreateFolder = () => {
    if (newFolder.name && newFolder.date) {
      if (isEditing) {
        setFolders(
          folders.map((folder) =>
            folder.id === selectedFolder.id ? { ...folder, ...newFolder } : folder
          )
        );
        setIsEditing(false);
      } else {
        setFolders([...folders, { ...newFolder, id: Date.now() }]);
      }
      setNewFolder({ name: '', date: '', images: [] });
      setShowCreateFolder(false);
    }
  };

  const handleEditFolder = (folder) => {
    setSelectedFolder(folder);
    setNewFolder({ id: folder.id, name: folder.name, date: folder.date, images: folder.images });
    setIsEditing(true);
    setShowCreateFolder(true);
  };

  const resetForm = () => {
    setSelectedFolder(null)
    setNewFolder({
      name: '', 
      images: []
    });
  };

  return (
    <div className="min-h-screen max-h-screen w-full px-10 overflow-scroll">
      <div className="py-5 mx-auto flex items-center justify-between gap-4">
        <div className="w-[70%] flex gap-6">
          <SearchCom />
          <button
            onClick={() => { setShowCreateFolder(true); resetForm(); }}
            className="bg-gray-900 text-white px-4 py-2 rounded-full hover:bg-gray-800 transition-colors flex items-center gap-2"
          >
            Create Folder <span>+</span>
          </button>
        </div>
        <div>
          <img src="/assets/mask.svg" alt="" />
        </div>
      </div>
      <div className="bg-gray-50 rounded-md mt-6">
        {showCreateFolder && (
          <FolderCreation
            newFolder={newFolder}
            setNewFolder={setNewFolder}
            handleCreateFolder={handleCreateFolder}
            setShowCreateFolder={setShowCreateFolder}
            isEditing={isEditing}
            setSelectedFolder={setSelectedFolder}
            setShowDeleteConfirm={setShowDeleteConfirm}
          />
        )}
        {!showCreateFolder && (
          <FolderGrid
            folders={folders}
            setSelectedFolder={setSelectedFolder}
            setShowDeleteConfirm={setShowDeleteConfirm}
            onEdit={handleEditFolder}
          />
        )}
        <ConfirmationModal
          isOpen={showDeleteConfirm}
          onClose={() => setShowDeleteConfirm(false)}
          onConfirm={() => {
            console.log(selectedFolder);
            setFolders(folders.filter((folder) => folder.id !== selectedFolder.id));
            setShowCreateFolder(false);
            setShowDeleteConfirm(false);
          }}
          message="Are you sure to delete this folder?"
        />
      </div>
    </div>
  );
};

export default PhotoManagementCon;
