import React from 'react'

// Confirmation Modal Component
const ConfirmationModal = ({ isOpen, onClose, onConfirm, type, message }) => {
    if (!isOpen) return null;
    console.log(message)
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-xl p-6 w-full max-w-sm">
          <p className="text-center mb-6">
            { message === "" || null || undefined ?
            <>{message}</> 
            :
            <>
              Are you sure to {type} this on Sponsorship?
            </> 
            }
          </p>
          <div className="grid grid-cols-2 gap-4">
            <button
              onClick={onClose}
              className="px-4 py-2 border rounded-md hover:bg-gray-50 transition-colors"
            >
              No
            </button>
            <button
              onClick={onConfirm}
              className="px-4 py-2 text-white bg-gray-900 rounded-md hover:bg-gray-800 transition-colors"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    );
};  

export default ConfirmationModal
