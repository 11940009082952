import React from 'react'
import Sidebar from '../../components/SideBar'
import PhotoManagementCon from './components/GalleryContainer'

function GalleryManagement() {
  return (
    <div className='flex bg-white'>
        <div className='bg-[#272938]'><Sidebar/></div>
        <div className='w-full'><PhotoManagementCon/></div>
    </div>
  )
}

export default GalleryManagement
