import React, { useEffect, useState } from 'react';
import { Upload } from 'lucide-react';

const SponsorModal = ({ isOpen, onClose, sponsor, isEditing, onSave }) => {
  const [formData, setFormData] = useState({
    image: null,
    name: '',
    description: '',
    rating: 'Economical',
    multipleRepetition: false
  });

  useEffect(() => {
    if (sponsor) {
      setFormData({
        image: sponsor.image || null,
        name: sponsor.name || '',
        description: sponsor.description || '',
        rating: sponsor.rating || 'Economical',
        multipleRepetition: sponsor.multipleRepetition || false
      });
    } else {
      setFormData({
        image: null,
        name: '',
        description: '',
        rating: 'Economical',
        multipleRepetition: false
      });
    }
  }, [sponsor]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormData({ ...formData, image: file });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl p-6 w-full max-w-md relative">
        <button onClick={onClose} className="absolute right-4 top-2 text-gray-500 hover:text-gray-700">X</button>

        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label className="block text-sm font-medium mb-1">Image</label>
            <div className="relative h-40 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center cursor-pointer hover:border-gray-400">
              <input type="file" accept="image/*" onChange={handleImageUpload} className="hidden" id="sponsor-image" />
              <label htmlFor="sponsor-image" className="flex flex-col items-center justify-center h-full w-full text-sm text-gray-500 cursor-pointer">
                {formData?.image ? (
                  formData.image instanceof File ? (
                    <img src={URL.createObjectURL(formData.image)} alt="Preview1" className="max-h-full max-w-full object-contain rounded-lg" />
                  ) : (
                    <img src={`${process.env.REACT_APP_BASE_URL}/uploads/${sponsor.image}`} alt="Preview2" className="max-h-full max-w-full object-contain rounded-lg" />
                  )
                ) : (
                  <div className="text-center">
                    <Upload className="mx-auto text-gray-400 mb-2" size={24} />
                    <span>Upload Image</span>
                  </div>
                )}
              </label>
            </div>
          </div>

          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-1">Name</label>
              <input type="text" className="w-full px-3 py-2 border rounded-lg" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">Profile Description</label>
              <textarea className="w-full px-3 py-2 border rounded-lg" rows="4" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
            </div>

            <div>
              <label className="block text-sm font-medium mb-1">Sponsor Rating</label>
              <select className="w-full px-3 py-2 border rounded-lg" value={formData.rating} onChange={(e) => setFormData({ ...formData, rating: e.target.value })}>
                <option>Economical</option>
                <option>Standard</option>
                <option>Premium</option>
              </select>
            </div>

            <div className="flex items-center justify-between">
              <label className="text-sm font-medium">Multiple Repetition</label>
              <label className="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" checked={formData.multipleRepetition} onChange={(e) => setFormData({ ...formData, multipleRepetition: e.target.checked })} className="sr-only peer" />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:bg-blue-600 after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:after:translate-x-full peer-checked:after:border-white "></div>
              </label>
            </div>
          </div>

          <button type="submit" className="w-full bg-gray-900 text-white rounded-lg py-2 hover:bg-gray-800">Save</button>
        </form>
      </div>
    </div>
  );
};

export default SponsorModal;
