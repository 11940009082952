import React, { useState } from 'react'
import { MapPin, Calendar, ChevronLeft, ChevronRight } from 'lucide-react';

function EventCard({ name, description, date, location, images, onManage }) {
    console.log(images);
    const [activeSlide, setActiveSlide] = useState(0);
    const nextSlide = () => {
        setActiveSlide((prev) => (prev === images?.length - 1 ? 0 : prev + 1));
    };

    const prevSlide = () => {
        setActiveSlide((prev) => (prev === 0 ? images?.length - 1 : prev - 1));
    };


    return (
        <div className="max-w-sm rounded-xl bg-white shadow-lg p-4">
            {/* Image Carousel */}
            <div className="relative mb-4">
                <div className="relative h-48 rounded-lg overflow-hidden">
                    {
                        images?.length > 0 &&
                            images[activeSlide] instanceof File ? (
                                <img src={URL.createObjectURL(images[activeSlide])} alt="Preview1" className="w-full h-full object-cover" />
                            ) : (
                                <img src={`${images?.[activeSlide]}`} alt="Preview2" className="w-full h-full object-cover" />
                            )
                    }
                    <button onClick={prevSlide} className="absolute left-2 top-1/2 -translate-y-1/2 bg-white/50 hover:bg-white/80 rounded-full p-1 transition-all">
                        <ChevronLeft size={20} />
                    </button>
                    <button onClick={nextSlide} className="absolute right-2 top-1/2 -translate-y-1/2 bg-white/50 hover:bg-white/80 rounded-full p-1 transition-all">
                        <ChevronRight size={20} />
                    </button>
                </div>
            </div>

            {/* Dots Indicator */}
            <div className="flex text-center items-center w-full justify-center mb-2 gap-2">
                {images?.map((_, index) => (
                    <button key={index} onClick={() => setActiveSlide(index)} className={`w-2.5 h-2.5 rounded-full transition-all ${activeSlide === index ? 'bg-black' : 'bg-gray-300'}`} />
                ))}
            </div>

            {/* Content */}
            <div className="space-y-3">
                <h2 className="text-xl font-semibold w-full text-center">{name}</h2>
                <p className="text-gray-600 text-sm">{description}</p>

                {/* Event Details */}
                <div className="space-y-2">
                    <div className="flex items-center gap-2 text-gray-600">
                        <Calendar size={16} />
                        <span className="text-sm">{date}</span>
                    </div>
                    <div className="flex items-center gap-2 text-gray-600">
                        <MapPin size={16} />
                        <span className="text-sm">{location}</span>
                    </div>
                </div>

                {/* Action Button */}
                <button onClick={onManage} className="w-full py-2 border-2 border-gray-300 rounded-full hover:bg-gray-50 transition-colors">
                    Manage Event
                </button>
            </div>
        </div>
    );
}

export default EventCard;

