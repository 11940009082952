import React from 'react'
import Sidebar from '../../components/SideBar'
import StoryManagementCon from './components/StorymanagementCon'

function StoryManagement() {
  return (
    <div className='flex bg-white'>
        <div className='bg-[#272938]'><Sidebar/></div>
        <div className='w-full'><StoryManagementCon/></div>
    </div>
  )
}

export default StoryManagement
